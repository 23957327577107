@font-face {
    font-family: 'PFDinDisplayPro';
    src: url('https://static.tildacdn.com/tild6537-6135-4463-b834-393561653663/PFDinDisplayPro-Ligh.woff') format('woff');
    font-weight: 300;
    font-style: normal
}

@font-face {
    font-family: 'PFDinDisplayPro';
    src: url('https://static.tildacdn.com/tild3061-3030-4530-a638-383239393964/PFDinDisplayPro-Regu.woff') format('woff');
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: 'PFDinDisplayPro';
    src: url('https://static.tildacdn.com/tild6265-6361-4864-a166-336637356432/PFDinDisplayPro-Bold.woff') format('woff');
}
