.btn-outline-danger {
    color: $primary-color;
    border-color: $primary-color;
}

.btn-danger {
    border-color: $primary-color;
    background-color: $primary-color;
}

.btn-group-toggle {
    .btn-outline-secondary:not(:disabled):not(.disabled).active {
        background-color: $primary-color;
        border-color: $primary-color;
        color: #fff;
    }
}

.element-wrapper {
    width: 100%;
    margin-top: 20px;
    label {
        margin: 0;
    }
    .error-message {
        color: #c73219;
        font-size: 0.95rem;
    }
}

#map {
    margin-top: 20px;
}

.radios {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.cta {
    margin-top: 20px;
}

.content-wrapper {
    .result-container {
        margin-top: 20px;
        h3 {
            font-size: 1.4rem;
            span {
                color: $primary-color;
                cursor: pointer;
            }
        }
        table {
            width: 100%;
            background-color: #fff;
            border-top: #ced4da 1px solid;
            border-left: #ced4da 1px solid;
            td, th {
                border-bottom: #ced4da 1px solid;
                border-right: #ced4da 1px solid;
                padding: 11px 13px;
                line-height: 1;
            }
        }
    }
}
