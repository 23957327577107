// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'PFDinDisplayPro',Arial,sans-serif;
$font-size-base: 1.1rem;
$line-height-base: 1.6;
$text-color: #000;
$primary-color: #c6503a;
$dark-color: #1d1d1d;
$light-color: #f5f5f5;
