body {
    font-family: $font-family-sans-serif;
    font-size: $font-size-base;
    color: $text-color;
    font-weight: 200;
}

.wrap {
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;


    header {
        background-color: $dark-color;
        color: #fff;
        padding: 15px 0;
        .logo > img {
            width: 90px;
            height: auto;
        }
    }

    .content-wrapper {
        background-color: $light-color;
    }

    footer {
        background-color: $dark-color;
        color: #fff;
        padding: 15px 0;
    }

    header, footer {
        .container {
            display: flex;
            justify-content: space-between;
        }
    }
}
