// Fonts
//@import url('https://fonts.googleapis.com/css?family=Open+Sans:600|Roboto:400,500');
@import "font";

// Variables
@import 'variables';

// Vendors
@import '~select2/dist/css/select2.css';
@import '~bootstrap/dist/css/bootstrap.css';
@import '~bootstrap/dist/css/bootstrap-grid.css';
@import '~bootstrap/dist/css/bootstrap-reboot.css';

// @see https://github.com/ttskch/select2-bootstrap4-theme
@import "select2_theme/select2-bootstrap4.min.css";

@import "elements";
// Layout
@import "layout";
